import { TranslationService } from '@shared/modules/translations/services/translation.service';

export enum SchoolStatuses {
  suspended = 'account_suspended',
  pending = 'payment_pending',
  active = 'good'
}

export type SchoolStatusesEnumMap = {
  [K in SchoolStatuses]: string;
};

export function getSchoolStatusDisplayValues(translationService?: TranslationService): SchoolStatusesEnumMap {
  if (translationService) {
    return {
      [SchoolStatuses.active]: translationService.translate(`schoolStatuses.${SchoolStatuses.active}`),
      [SchoolStatuses.pending]: translationService.translate(`schoolStatuses.${SchoolStatuses.pending}`),
      [SchoolStatuses.suspended]: translationService.translate(`schoolStatuses.${SchoolStatuses.suspended}`)
    };
  } else {
    return {
      [SchoolStatuses.active]: 'Active',
      [SchoolStatuses.pending]: 'Pending',
      [SchoolStatuses.suspended]: 'Suspended'
    };
  }
}
